import $ from "jquery";
window.jQuery = $;
window.$ = $;

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
// require("theme/main.5fb56474af9319a1be42.js");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/widgets/autocomplete");
require("theme/bootstrap.bundle.js");
require("lib/app.js");
require("lib/demo.js");
require("theme/input-mask.js");
require("trix")
require("@rails/actiontext")

require("theme/bootstrap-toggle.min.js");
require("theme/jquery.toast.js");
require("theme/owl.carousel.min.js");

$( document ).ready(function() {
	$(".input-mask-trigger").inputmask();
});

$(document).ready(function(){
  //attach csrf token with every ajax
  $.ajaxSetup({
    headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });
  confirm_rails();

  // prevent from remote true pages error
  $.each($("a[data-remote='true']:not([data-paging])"), function(i, val) {
    $(val).data("url", $(val).attr("href")).attr("href", "javascript://")
  });

  $.rails.href = function(el) {
    var $el = $(el);
    return $el.data('url') || $el.data('href') || $el.attr('href');
  }
});

function confirm_rails(){
  $(document).on('mousedown','a[data-confirm]',function(e){
    let $ = window.$b;
    e.preventDefault()
    let link = window.jQuery(this);
    let message = link.data('confirm');
    let cancel_text = link.data('cancel-text');
    let ok_text = link.data('ok-text');
    let modal = window.jQuery("#modal_confirm");
    modal.find('.modal-body').text(message);

    if (cancel_text != ''){
      modal.find('.modal-footer').find('#modal-cancel').text(cancel_text);
    }
    if (ok_text != ''){
      modal.find('.modal-footer').find('#modal-accept').text(ok_text);
    }
    
    let approve = modal.find('.approve')
    approve.attr('data-method', link.data('method'));
    approve.attr('href', link.attr('href'));
    modal.modal('show')
  });
}

$(function(){
  var customRenderMenu = function(ul, items){
    var self = this;
    var categoryArr = [];
    
    function contain(item, array) {
      var contains = false;
      $.each(array, function (index, value) {
        if (item == value) {
          contains = true;
          return false;
        }
      });
      return contains;
    }
    
    $.each(items, function (index, item) {
      if (! contain(item.category, categoryArr)) {
        categoryArr.push(item.category);
      }
    });
    
    $.each(categoryArr, function (index, category) {
      ul.append("<li class='ui-autocomplete-group'>" + category + "</li>");
      $.each(items, function (index, item) {
        if (item.category == category) {
          self._renderItemData(ul, item);
        }
      });
    });
  };
    
  $("#tags").autocomplete({
    source: "/key_search",
    create: function () {
      //access to jQuery Autocomplete widget differs depending 
      //on jQuery UI version - you can also try .data('autocomplete')
      $(this).data('uiAutocomplete')._renderMenu = customRenderMenu;
    },
    select: function (a, b) {
      window.location = b.item.link
    }
  });
});