import consumer from "./consumer"

consumer.subscriptions.create("RoomChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    // $('.card-body-scroll').animate({scrollTop:20000}, 'slow')
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    let elem = $('[data-channel-subscribe="user"]');
    let receiverId = $(elem).attr('data-reciver-id');
    let curr_user = data.userid;
    let dt_time = data.dt_time;

    let notified_user = $("body").attr('data-curr-user-id');
    // send notifcation alert message to receiver
    let is_sender = false;
    if (curr_user == parseInt(notified_user)){
      is_sender = true;
    }
    // send notifcation alert message to receiver
    /*if (msgData.receiver_id == parseInt(notifyUser)){
      window.jQuery.toast({
        heading:`New Message from ${data.message_from}`,
        text: `${msgData.message}`,
        position: 'top-right',
        loaderBg: '#1283da',
        icon: 'info',
        hideAfter: 10000,
        stack: 6
      });
    }*/

    let content = `<div class="${is_sender ? 'float-right' : ''}"><div class="chat-box-wrapper ${is_sender ? 'chat-box-wrapper-right' : ''}">`+
                      `<div>`+
                        `<div class="chat-box">${data.content}</div>`+
                        `<small class="opacity-6">`+
                        `<i class="fa fa-calendar-alt mr-1"></i> ${dt_time} ago`+
                        `</small>`+
                      `</div>`+
                      `<div>`+
                        `<div class="avatar-icon-wrapper mr-1">`+
                          `<div class="avatar-icon avatar-icon-lg rounded">`+
                            `<img src="${data.profile_img}">`+
                          `</div>`+
                          `<h6 class="text-center">${data.msg_user_name}</h6>`+
                        `</div>`+
                      `</div>`+
                    `</div></div><div class="clearfix"></div>`;

    if (!is_sender) {
      content = `<div class="${is_sender ? 'float-right' : ''}"><div class="chat-box-wrapper ${is_sender ? 'chat-box-wrapper-right' : ''}">`+
                  `<div>`+
                    `<div class="avatar-icon-wrapper ml-1">`+
                      `<div class="avatar-icon avatar-icon-lg rounded">`+
                        `<img src="${data.profile_img}">`+
                      `</div>`+
                      `<h6 class="text-center">${data.msg_user_name}</h6>`+
                    `</div>`+
                  `</div>`+
                  `<div>`+
                    `<div class="chat-box">${data.content}</div>`+
                    `<small class="opacity-6">`+
                    `<i class="fa fa-calendar-alt mr-1"></i> ${dt_time} ago`+
                    `</small>`+
                  `</div>`+
                `</div></div><div class="clearfix"></div>`;
    }

    elem.append(content);
    elem.animate({ scrollTop: elem.prop("scrollHeight")}, 1000);
    $('#message_content').val('');
    // scroll box to bottom when message is appended
    // $('.card-body-scroll').animate({scrollTop:20000}, 'slow')

    // Called when there's incoming data on the websocket for this channel
  }
});
